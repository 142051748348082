import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactBreakpoints from 'react-breakpoints';
import { bpAsNumber } from 'styles/base/variables/mediaQueries';
// import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTMID,
// };
//
// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <ReactBreakpoints breakpoints={bpAsNumber}>
    <App />
  </ReactBreakpoints>,
  document.getElementById('root')
);
